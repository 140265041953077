import React, { useState, ReactNode } from 'react';
import { IModalRevealCtaFields } from '@/types/contentful';
import { useMediaQuery } from '~/hooks/useMediaQuery';
import { SignUpCard } from '../SignUpCard';
import { createPortal } from 'react-dom';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  if (!isOpen) return null;

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  return createPortal(
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    <div
      className="fixed inset-0 z-[49] flex items-center justify-center bg-black/70"
      data-testid="overlay-backdrop"
      onClick={onClose}
      onKeyDown={handleKeyDown}
      role="dialog"
      aria-modal="true"
    >
      {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
      <div
        className="relative z-50"
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
        role="document"
      >
        {children}
      </div>
      <button
        onClick={onClose}
        className="absolute right-3 top-3 cursor-pointer border-none bg-none text-2xl text-white"
        aria-label="Close modal"
      >
        ×
      </button>
    </div>,
    document.body
  );
};

export function ModalRevealCTA({
  buttonText,
  mobileSlug,
  signUpCard
}: IModalRevealCtaFields) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const isMobile = !useMediaQuery('tablet');

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleClick = () => {
    if (isMobile) {
      window.location.href = mobileSlug ?? '';
    } else {
      openModal();
    }
  };

  return (
    <>
      <button
        onClick={handleClick}
        className="flex h-10 min-w-[200px] items-center justify-center rounded-lg bg-limegreen px-4 py-2 text-center font-medium"
      >
        {buttonText}
      </button>

      <Modal isOpen={modalIsOpen} onClose={closeModal}>
        <SignUpCard variant={'large'} {...signUpCard?.fields} />
      </Modal>
    </>
  );
}
