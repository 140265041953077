import React, { useEffect, useRef, useState } from 'react';
import { Asset } from 'contentful';
import { useSpring, animated, easings } from 'react-spring';
import { useTypewriter, Cursor } from 'react-simple-typewriter';

import { IFullBleedHeroFields } from '@/types/contentful';

import { getImageDataFromOptions } from '../../utils/contentful';
import { usePageDataContext } from '../../context/PageData';
import { TextWithOptions } from '../Text/TextWithOptions';

import CtaWidget from '../CtaWidget';

import PrivacyPolicyDisclaimer from 'src/components/PrivacyPolicyDisclaimer';
import GlImage from '../Image';
import AdditionalContent from '../AdditionalContent';
import { ModalRevealCTA } from '../ModalRevealCTA';
import { SocialShare } from '../LearningCenter/Components/ArticleHeader/SocialShare';
import cx from 'classnames';

export function FullBleedHero({
  headingText,
  headingTextOptions,
  desktopBackgroundImage,
  desktopBackgroundImageOptions,
  desktopForVideosDominantColor,
  mobileBackgroundImage,
  mobileBackgroundImageOptions,
  privacyPolicyDisclaimer,
  ctaWidget,
  additionalContent,
  returningUserHeadingText,
  shareIcons,
  animationType = 'opacity',
  typewriterHeaders,
  animatedTextDecoration
}: IFullBleedHeroFields) {
  const { query: { gl_content, g_adgroupid, utm_source } = {}, isReturning } =
    usePageDataContext();

  // Determine if typewriter animation should be used
  const isTypewriter =
    animationType === 'typewriter' &&
    typewriterHeaders &&
    typewriterHeaders.length > 0;

  const longestWord = isTypewriter
    ? typewriterHeaders.reduce((a, b) => (a.length > b.length ? a : b), '')
    : '';

  const combinedText = `${headingText} ${longestWord}`;

  const headingRef = useRef<HTMLSpanElement>(null);
  const containerRef = useRef<HTMLHeadingElement>(null);

  const [shouldWrap, setShouldWrap] = useState(false);

  useEffect(() => {
    if (headingRef.current && containerRef.current) {
      const headingWidth = headingRef.current.scrollWidth;
      const containerWidth = containerRef.current.clientWidth;

      if (headingWidth > containerWidth) {
        setShouldWrap(true);
      } else {
        setShouldWrap(false);
      }
    }
  }, [combinedText]);

  // Animation for headingText (only if animationType is 'opacity')
  const headingProps = useSpring({
    to: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(20px)' },
    delay: animationType !== 'opacity' ? 0 : 400,
    config: {
      duration: 3000,
      easing: easings.easeOutExpo
    },
    immediate: animationType !== 'opacity' // Disable animation if typewriter is selected
  });

  // Animation for CTA and Privacy Policy (this will always use useSpring)
  const ctaProps = useSpring({
    to: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(20px)' },
    delay: animationType !== 'opacity' ? 0 : 700,
    config: {
      duration: 3000,
      easing: easings.easeOutExpo
    },
    immediate: animationType !== 'opacity'
  });

  // Use useTypewriter unconditionally
  const [text] = useTypewriter({
    words: isTypewriter
      ? typewriterHeaders.map((header) => header + '.')
      : [''],
    loop: isTypewriter ? 0 : false,
    typeSpeed: isTypewriter ? 100 : 0,
    deleteSpeed: isTypewriter ? 100 : 0,
    delaySpeed: isTypewriter ? 2000 : 0
  });

  // Determine the heading content
  const headingContent = isTypewriter ? (
    <h1
      ref={containerRef}
      className={cx(
        '!max-w-[375px] !break-words tablet:!max-w-[680px]',
        animatedTextDecoration
          ? 'mb-4 text-left text-heading2 font-normal leading-[115%] text-white tablet:!text-[52px] desktop:!text-[4rem] [@media(width>=375px)]:text-[32px] [@media(width>=375px)]:leading-[38px]'
          : 'mb-8 max-w-[375px] break-words text-left text-5xl !leading-[115%] text-white tablet:mb-4 tablet:max-w-[680px] tablet:text-6xl'
      )}
    >
      {headingText} {/* Hidden span to reserve space for the longest word */}
      {shouldWrap ? <br /> : ' '}
      <span
        ref={headingRef}
        className="inline-block whitespace-nowrap"
        style={{ minWidth: `${longestWord.length}ch` }}
      >
        {text}
        <Cursor />
      </span>
    </h1>
  ) : (
    <animated.div style={headingProps}>
      <TextWithOptions
        as="h1"
        v2
        className={cx(
          animatedTextDecoration
            ? 'mb-4 text-left text-heading2 font-normal leading-[46px] text-white tablet:!text-[40px] tablet:!leading-[48px] desktop:!text-[4rem] desktop:!leading-[74px] [@media(width>=375px)]:text-[32px] [@media(width>=375px)]:leading-[38px]'
            : 'mb-8 max-w-[350px] text-left text-5xl !leading-[115%] text-white tablet:mb-4 tablet:max-w-[644px] tablet:text-6xl'
        )}
        data-testid="fullbleed-hero-headline"
        keyword={gl_content}
        channel={utm_source}
        adGroupId={g_adgroupid}
        fallback={
          (isReturning
            ? returningUserHeadingText ?? headingText
            : headingText) || ''
        }
        options={headingTextOptions}
        module="FullBleed Hero heading"
      />
    </animated.div>
  );

  // Check if assets are videos (based on content type)
  const isDesktopVideo =
    desktopBackgroundImage?.fields?.file?.contentType?.includes('mp4') ||
    desktopBackgroundImage?.fields?.file?.contentType?.includes('video');
  const isMobileVideo =
    mobileBackgroundImage?.fields?.file?.contentType?.includes('mp4') ||
    mobileBackgroundImage?.fields?.file?.contentType?.includes('video');

  return (
    <div
      data-blockid="full-bleed-hero"
      data-testid="full-bleed-hero"
      data-e2e="hero"
      className="mb-16"
    >
      <div className="relative grid w-full desktop:max-h-[85vh]">
        {/* Desktop */}
        <div
          className={
            'hidden w-full max-w-[100vw] desktop:flex desktop:max-h-[85vh] desktop:min-h-[650px]'
          }
          style={{ gridArea: '1/1', background: desktopForVideosDominantColor }}
        >
          {isDesktopVideo ? (
            <video
              autoPlay
              muted
              playsInline
              id="desktop-video-background"
              className="h-full w-full object-cover object-center [@media(width>=1751px)]:object-contain"
            >
              <source
                src={desktopBackgroundImage?.fields?.file?.url}
                type="video/mp4"
              />
            </video>
          ) : (
            <GlImage
              {...getImageDataFromOptions(
                gl_content,
                utm_source,
                g_adgroupid,
                desktopBackgroundImage as Asset,
                desktopBackgroundImageOptions
              )}
              className="aspect-[1440/700] h-full w-full object-top"
              loading="eager"
              heroImage={true}
              layout="fill"
              objectFit="cover"
              width={undefined}
              height={undefined}
              alt="desktop-background-image"
            />
          )}
        </div>
        {/* Mobile */}
        <div
          className="block max-h-[740px] tablet:max-h-[876px] desktop:hidden"
          style={{ gridArea: '1/1', background: desktopForVideosDominantColor }}
        >
          {isMobileVideo ? (
            <video
              autoPlay
              muted
              playsInline
              className="aspect-[828/1200] h-full w-full object-cover object-top [@media(width>=635px)]:mx-auto [@media(width>=635px)]:w-[635px] [@media(width>=635px)]:max-w-[635px]"
            >
              <source
                src={mobileBackgroundImage?.fields?.file?.url}
                type="video/mp4"
              />
            </video>
          ) : (
            <div className="aspect-[1440/2096] h-full w-full object-fill object-center">
              <GlImage
                {...getImageDataFromOptions(
                  gl_content,
                  utm_source,
                  g_adgroupid,
                  mobileBackgroundImage as Asset,
                  mobileBackgroundImageOptions
                )}
                loading="eager"
                heroImage={true}
                layout="fill"
                objectFit="cover"
                objectPosition={'top'}
                alt="mobile-background-image"
              />
            </div>
          )}
        </div>
        <div
          style={{
            gridArea: '1/1',
            position: 'relative',
            placeItems: 'center',
            display: 'grid'
          }}
        >
          <div className="block-container block h-full w-full !justify-normal desktop:flex desktop:flex-col">
            <div className="mt-auto flex h-full w-full max-w-[100vw] px-5 pb-5 align-bottom tablet:px-10 tablet:pb-10 desktop:px-15 desktop:pb-15">
              <div className="w-full self-end desktop:max-w-[655px]">
                {headingContent}
                <animated.div
                  style={animationType === 'opacity' ? ctaProps : {}}
                >
                  {ctaWidget &&
                    ctaWidget.sys.contentType.sys.id ===
                      'registrationEntrypoint' && (
                      <div className="desktop:mb-0 desktop:max-w-[350px]">
                        <CtaWidget
                          width={'100%'}
                          {...ctaWidget?.fields}
                          sysId={ctaWidget?.sys?.contentType?.sys?.id}
                          id="fullwidth-hero-cta"
                          module="Hero"
                          isMobile={false}
                          responsive
                        />
                      </div>
                    )}
                  {ctaWidget &&
                    ctaWidget.sys.contentType.sys.id === 'modalRevealCta' && (
                      <div className="max-w-[350px] desktop:mb-0">
                        <ModalRevealCTA {...(ctaWidget.fields as any)} />
                      </div>
                    )}
                  {privacyPolicyDisclaimer && (
                    <div className="mt-2 max-w-[350px] tablet:max-w-[467px] desktop:max-w-[435px]">
                      <PrivacyPolicyDisclaimer
                        privacyPolicyDisclaimer={privacyPolicyDisclaimer}
                        displayPrivacyPolicyIcon={false}
                        color={'white'}
                        noMargin
                      />
                    </div>
                  )}
                  {shareIcons && (
                    <div className="mt-5">
                      <SocialShare inline hideShareCopy socialOptions={'all'} />
                    </div>
                  )}
                </animated.div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdditionalContent additionalContent={additionalContent} />
    </div>
  );
}
