import React, { useState, useEffect } from 'react';
import { ISignUpCardFields } from '@/types/contentful';
import PrivacyPolicyDisclaimer from '../PrivacyPolicyDisclaimer';
import { QRCode } from 'react-qrcode-logo';
import cx from 'classnames';
import CtaWidget from '../CtaWidget';

const greenlightLogo = `
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7933_18813)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7846 0C6.17158 0 0 6.17158 0 13.7846V42.2154C0 49.8284 6.17158 56 13.7846 56H42.2154C49.8284 56 56 49.8284 56 42.2154V13.7846C56 6.17158 49.8284 0 42.2154 0H13.7846ZM28.27 26.0413H22.8169C22.0167 26.0413 21.4832 26.6615 21.4832 27.4026V28.7033C21.4832 29.4595 22.0167 30.0645 22.8169 30.0645H26.4326V33.0441C26.4326 35.1313 24.9211 36.6287 22.8169 36.6287H21.9723C19.6902 36.6287 18.3269 34.9801 18.3269 32.908V23.0618C18.3269 20.9746 19.705 19.3713 21.9723 19.3713H22.8169C24.4617 19.3713 25.499 20.2939 26.1955 21.4283C26.7289 22.3812 27.7662 22.729 28.7739 22.1845L29.2036 21.9425C30.0779 21.4586 30.3742 20.6116 30.0482 19.7495C28.7442 16.7396 25.9287 15.1667 22.8465 15.1667H21.8685C17.4527 15.1667 14 18.2521 14 22.8349V33.1651C14 37.7479 17.4527 40.8333 21.8685 40.8333H22.8021C27.3958 40.8333 30.641 37.7479 30.641 33.1651V28.2949C30.6558 27.0698 29.5148 26.0413 28.27 26.0413ZM42.7478 40.8181H41.5475C36.9835 40.8181 33.6345 37.6419 33.6345 32.7113V16.8303C33.6345 15.9682 34.3013 15.3329 35.146 15.3329H36.4796C37.3539 15.3329 37.9911 15.9833 37.9911 16.8303V32.2726C37.9911 34.6018 39.6656 36.4168 41.9179 36.4168H42.7922C43.6665 36.4168 44.3333 37.0369 44.3333 37.778V39.4569C44.3185 40.198 43.6221 40.8181 42.7478 40.8181Z" fill="#00D9B4"/>
    </g>
  </svg>
`;

interface Props extends ISignUpCardFields {
  variant: 'medium' | 'large';
}

export const SignUpCard = ({
  logo,
  header,
  subheader,
  bodyText,
  qrCodeHeader,
  qrCodeUrl,
  ctaWidget,
  privacyPolicyDisclaimer,
  variant
}: Props) => {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      // Set initial width
      handleResize();

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const logoUrl = logo?.fields.file.url;

  return (
    <div
      className={cx(
        'z-[1] inline-flex h-fit flex-col items-center justify-start gap-6 rounded-[32px] bg-white',
        {
          'max-w-[350px] px-5 py-8 tablet:max-w-[375px] tablet:px-8':
            variant === 'medium',
          'max-w-[350px] px-5 py-8 tablet:max-w-[375px] tablet:px-8 desktop:max-w-[420px] desktop:py-12':
            variant === 'large'
        }
      )}
    >
      <div
        className={cx('mx-auto', {
          'h-12': variant === 'medium',
          'h-12 desktop:h-14': variant === 'large'
        })}
      >
        {logoUrl ? (
          <img src={logoUrl} alt="Logo" className="h-full w-full" />
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: greenlightLogo }}
            className="h-full w-full"
          />
        )}
      </div>
      <div
        className={
          variant === 'large' ? 'flex flex-col gap-6' : 'flex flex-col gap-3'
        }
      >
        {header && (
          <h2
            className={cx('text-center', {
              'text-heading2-mobile leading-8 tablet:text-4xl':
                variant === 'medium',
              'text-heading2-mobile leading-8 tablet:text-4xl desktop:text-display-2 desktop:leading-9':
                variant === 'large'
            })}
          >
            {header}
          </h2>
        )}
        {subheader && (
          <p className="text-center font-normal leading-5">{subheader}</p>
        )}
        {bodyText && <p className="text-center leading-snug">{bodyText}</p>}
        {(qrCodeHeader || qrCodeUrl) && (
          <div className="hidden tablet:block">
            {qrCodeHeader && (
              <h3 className="mb-2 text-center font-medium">{qrCodeHeader}</h3>
            )}
            {qrCodeUrl && (
              <div className="flex justify-center">
                <div
                  className={cx(
                    'inline-block border-[3px] border-solid border-limegreen',
                    {
                      'rounded-[10px] p-[9px] desktop:rounded-[14px] desktop:p-3':
                        variant === 'large',
                      'rounded-[10px] p-[9px]': variant === 'medium'
                    }
                  )}
                >
                  <QRCode
                    value={qrCodeUrl}
                    size={150}
                    quietZone={0}
                    qrStyle="dots"
                    logoImage={
                      logoUrl ||
                      `data:image/svg+xml;base64,${btoa(greenlightLogo)}`
                    }
                    logoWidth={
                      variant === 'large' ? (windowWidth > 720 ? 36 : 26) : 26
                    }
                    logoHeight={
                      variant === 'large' ? (windowWidth > 720 ? 36 : 26) : 26
                    }
                    removeQrCodeBehindLogo={true}
                    fgColor="#000000"
                    bgColor="#ffffff"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {/** TODO: Need more metrics on whether two-CTA experience is needed from Marketing */}
      {/* {qrCodeUrl && (
        <a
          href={qrCodeUrl}
          className="flex w-fit min-w-32 items-center justify-center rounded-lg bg-limegreen px-4 py-2 tablet:hidden"
        >
          <p className="flex-grow text-center text-sm font-medium leading-6">
            {buttonText}
          </p>
        </a>
      )} */}
      <div className="flex flex-col gap-6">
        {ctaWidget && (
          <CtaWidget
            className="[&_form]:max-h-16"
            {...ctaWidget?.fields}
            responsive
            width={'100%'}
          />
        )}
        {privacyPolicyDisclaimer && (
          <div className="[&_p]:!w-full [&_p]:!px-3 [&_p]:!text-center">
            <PrivacyPolicyDisclaimer
              privacyPolicyDisclaimer={privacyPolicyDisclaimer}
              displayPrivacyPolicyIcon={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};
